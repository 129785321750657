<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}.detalle`,
              params: { id: rs_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${data_table.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.rs_administratives.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="formAdd"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RsData :rs_id="rs_id" />
        </v-col>
        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Usuario'" />
                      <th v-text="'Comentario'" />
                      <th v-text="'Acción'" width="150px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in data_table" :key="i">
                      <td
                        class="font-weight-bold"
                        v-text="data_table.length - i"
                      />
                      <td v-text="item.created_at" />
                      <td v-text="item.created_by.email" />
                      <td
                        class="text-description"
                        v-text="
                          item.comment
                            ? item.comment.length > 300
                              ? `${item.comment.substr(0, 300)}...`
                              : item.comment
                            : ''
                        "
                      />
                      <td width="140px">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="secondary"
                              dark
                              @click="formEditView(item.id, false)"
                            >
                              <v-icon v-text="'mdi-eye'" />
                            </v-btn>
                          </template>
                          <span v-text="'Ver'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="login.permissions.rs_administratives.update"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="info"
                              dark
                              @click="formEditView(item.id, true)"
                            >
                              <v-icon v-text="'mdi-pencil'" />
                            </v-btn>
                          </template>
                          <span v-text="'Editar'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="login.permissions.rs_administratives.delete"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="error"
                              dark
                              @click="desactivate(item.id)"
                            >
                              <v-icon v-text="'mdi-delete'" />
                            </v-btn>
                          </template>
                          <span v-text="'Eliminar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog STOREUPDATE start -->
    <v-dialog v-model="form_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :loading="loading ? 'grey darken-2' : false"
        :disabled="loading"
      >
        <v-toolbar dark>
          <v-toolbar-title v-text="data.id ? 'Editar' : 'Agregar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="formDialogClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_dialog">
          <v-form v-on:submit.prevent ref="form_submit" lazy-validation>
            <v-row>
              <v-col cols="12" class="mt-3">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2
                      class="text-caption"
                      v-text="'SEGUIMIENTO ADMINISTRATIVO'"
                    />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-textarea
                          v-model="data.comment"
                          label="Comentario"
                          row="1"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  :color="data.id ? 'info' : 'success'"
                  @click="formSubmit"
                >
                  <v-icon v-text="`mdi-${data.id ? 'pencil' : 'plus'}`" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog STOREUPDATE end -->
    <!-- Dialog VIEW start -->
    <v-dialog v-model="view_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Ver'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="view_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="view_dialog">
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="'SEGUIMIENTO ADMINISTRATIVO'"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <ViewData
                        label="Comentario"
                        :value="data.comment"
                        class="text-description"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog VIEW end -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  headersToken,
  URL_API,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import RsData from "../../components/RsData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    RsData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "servicios",
      api: "rss/administratives",
      login: this.$store.getters.getLogin,
      rs_id: this.$route.params.id,
      loading: true,
      rules: rules(),
      data_table: [],
      data: {},
      form_dialog: false,
      view_dialog: false,
      documents: [],
      documents_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getDataTable() {
      this.loading = true;

      index(this.api, this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "rs_id",
          value: this.rs_id,
        },
      ]).then((response) => {
        this.data_table = response.data;
        this.loading = false;
      });
    },
    defaultData() {
      this.data = {
        id: null,
        comment: "",
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rs_id: this.$attrs.id,
      };
    },
    formAdd() {
      this.defaultData();
      this.form_dialog = true;
    },
    formEditView(id, edit) {
      show(this.api, this.login.token, id).then((response) => {
        this.data = response;
      });

      if (edit) {
        this.form_dialog = true;
      } else {
        this.view_dialog = true;
      }
    },
    formDialogClose() {
      this.form_dialog = false;
      this.defaultData();
      this.$refs.form_submit.resetValidation();
    },
    formSubmit() {
      if (this.$refs.form_submit.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma ${this.data.id ? "editar" : "agregar"} el registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              storeUpdate(
                this.api,
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.getDataTable();
                  this.defaultData();
                  this.form_dialog = false;
                } else {
                  console.log(response.message);
                  this.loading = false;
                  this.form_dialog = true;
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    desactivate(id) {
      this.$swal
        .fire(msgConfirm("¿Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(this.api, this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getDataTable()
                  : console.log(response.message);
              }
            );

            this.getDataTable();
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getDataTable();
  },
};
</script>

<style>
.text-description {
  white-space: pre-line;
}
</style>